<!-- 任务工单列表 -->
<template>
  <div class="container">
    <div class="header">
      <span class="header-tit">任务工单</span>
      <div class="hebtns">
        <el-button type="primary" class="common-screen-btn btn"
          >发起任务</el-button
        >
      </div>
    </div>
    <!-- 筛选 -->
    <div class="body common-screen-container">
      <div class="common-input-container">
        <span>任务工单名称：</span>
        <el-input
          v-model="input"
          class="common-screen-input"
          placeholder="请输入"
          maxlength="16"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>类型名称：</span>
        <el-select
          v-model="value"
          placeholder="请选择"
          class="common-screen-input"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>优先级：</span>
        <el-select
          v-model="value"
          placeholder="请选择"
          class="common-screen-input"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <span>工单进展状态：</span>
        <el-select
          v-model="value"
          placeholder="请选择"
          class="common-screen-input"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>计划开始时间：</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <div class="common-input-container">
        <span>计划完成时间：</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <br />
      <div class="common-input-container">
        <span>当前处理人：</span>
        <el-input
          v-model="input"
          class="common-screen-input"
          placeholder="请输入"
          maxlength="16"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>创建人：</span>
        <el-input
          v-model="input"
          class="common-screen-input"
          placeholder="请输入"
          maxlength="16"
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>负责人：</span>
        <el-input
          v-model="input"
          class="common-screen-input"
          placeholder="请输入"
          maxlength="16"
        ></el-input>
      </div>
      <span class="common-input-container">
        <el-button
          style="margin: 0"
          class="common-screen-btn"
          type="primary"
          @click="() => getTaskList()"
          >查 询</el-button
        >
        <el-button
          class="common-screen-btn"
          plain
          @click="
            () => {
              resetBtn();
            }
          "
          >重 置</el-button
        >
      </span>
    </div>
    <!-- 列表 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="所有工单" name="all">
        <table-list ref="all"></table-list>
      </el-tab-pane>
      <el-tab-pane label="我的待办" name="myDo">
        222
      </el-tab-pane>
      <el-tab-pane label="我创建的" name="myCreate">
        333
      </el-tab-pane>
      <el-tab-pane label="我负责的" name="myLead">
        4
      </el-tab-pane>
      <el-tab-pane label="抄送给我的" name="giveMe">
        555
      </el-tab-pane>
      <el-tab-pane label="已处理任务" name="completed">
        666
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import tableList from "./children/tableList.vue"

export default {
  name: "taskList",
  components: {tableList},
  props: {},
  data() {
    return {
      input: "",
      textarea: "",
      value: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕"
        },
        {
          value: "选项2",
          label: "双皮奶"
        }
      ],
      value1: "",
      activeName: "all"
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getTaskList() {
      console.log("1");
    },
    resetBtn() {},
    handleClick() {
      console.log("2121");
      //   if (this.activeName == 'all') {
      //     this.$refs.orderAll.getRenewalList()
      //   } else {
      //     this.$refs.orderMy.getRenewalList()
      //   }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  padding: 0 16px 16px;
  text-align: left;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-tit {
      font-size: 20px;
      color: #101010;
    }
    .btn {
      width: 101px;
    }
  }
  .line {
    position: absolute;
    top: 66px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #eee;
  }
  .body {
    text-align: left;
    margin-top: 44px;
    .calname {
      margin-bottom: 24px;
    }
  }
}
</style>
